<script>
import { api } from "@/state/services";
import Dropdown from 'primevue/dropdown';

export default {
  props: {
    order: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Dropdown
  },
  data() {
    return {
      loading: false,
      errored: '',

      products: null,
      product: '',
      qty: 1,
    };
  },
  methods: {
    getProdutos() {
      api
        .get("produtos")
        .then((res) => {
          if (res.data.status == "success") {
            this.products = res.data.list;
          }
        })
    },
    editItem() {
      if (this.order && this.product.id && this.qty > 0) {
        this.loading = true;

        api
          .post("pedidos/" + this.order + "/itens", {
            id: this.product.id,
            qty: this.qty,
          })
          .then((res) => {
            if (res.data.status == "success") {
              this.product = "";
              this.qty = 1;

              this.$parent.$parent.item.add.modal = false;
              this.$parent.$parent.getPedido();
            } else {
              this.$toast.error(res.data.message);
            }

            this.loading = false;
          })
          .catch((error) => {
            this.errored = error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getProdutos();
  },
};
</script>

<template>
  <div>
    <b-modal id="item-add" v-model="modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-5">Editar produto</h4>

        <b-form-group id="product" label="Produto" label-for="product" class="required">
          <Dropdown v-model="product" :options="products" optionLabel="product" :filter="false" placeholder="Selecione um produto" :showClear="true">
            <template #value="slotProps">
              <div class="p-dropdown-product" v-if="slotProps.value">
                <img :alt="slotProps.value.image" :src="slotProps.value.image" />
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else class="p-dropdown-product">
                <img class="rounded" :src="'https://ui-avatars.com/api/?background=dedede&color=000&name=P'" alt="" />
                <div>{{ slotProps.placeholder }}</div>
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-product" v-if="slotProps.option">
                <img :alt="slotProps.option.name" :src="slotProps.option.image" />
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </b-form-group>

        <b-form-group id="qty" label="Quantidade" label-for="qty" class="required">
          <b-form-input id="qty" v-model="qty" type="number" min="1"></b-form-input>
        </b-form-group>

        <div class="d-flex justify-content-between mt-3">
          <button class="btn btn-default" :disabled="loading || !product.id || qty == 0" v-on:click="editItem()">
            Editar
            <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <a class="btn btn-outline-link" v-on:click="modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.p-dropdown {
  width: 100%;
}

.p-hidden-accessible {
  display: none;
}

.p-dropdown-panel {
  width: 100%;
  left: 0;
}

.p-dropdown-label,
.p-dropdown-item {
  display: flex;
  align-items: center;
  white-space: normal;
  height: 100px;
}

.p-placeholder {
  line-height: 85px;
}

.p-dropdown-product {
  display: flex;
  align-items: center;
}

.p-dropdown-product img {
  border-radius: 3px;
  width: 80px;
}

.p-dropdown-product div {
  margin-left: 10px;
  width: calc(100% - 90px);
  word-wrap: break-word;
  font-size: 14px;
  color: #000;
  line-height: 20px;
}

.p-highlight {
  background: #dedede !important;
}

.p-dropdown-items-wrapper {
  max-height: 50vh !important;
}
</style>