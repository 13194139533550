<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import ItemAdd from "@/components/widgets/pedidos-item-add";
import ItemEdit from "@/components/widgets/pedidos-item-edit";
import AquivosUpload from "@/components/widgets/upload-pedidos-arquivos";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    ItemAdd,
    ItemEdit,
    AquivosUpload
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    name: { required },
    store_id: {},
    store_order: {},
  },
  data() {
    return {
      loading: false,
      errored: false,

      order: null,

      fields: {
        items: [
          { key: "id", label: "#" },
          { key: "name", label: "Nome" },
          { key: "price", label: "Preço" },
          { key: "qty", label: "Qtd" },
          { key: "subtotal", label: "Subtotal" },
        ],
        files: [
          { key: "id", label: "#" },
          { key: "name", label: "Arquivo" },
          { key: "url", label: "" },
        ]
      },

      products: null,

      item: {
        add: {
          modal: false,
        },
        edit: {
          modal: false,
        },
        del: {
          modal: false,
          id: null,
        }
      },

      file: {
        add: {
          modal: false,
        },
        del: {
          modal: false,
          id: null,
        },
      },

      balance: 0,
      limit: 0,

      pay: {
        modal: false,
        loading: false,
        balance: 0,
      },

      cancel: {
        modal: false,
        loading: false,
      },
    };
  },
  methods: {
    getPedido() {
      this.loading = true;

      api
        .get("pedidos/" + this.$route.params.id)
        .then((res) => {
          if (res.data.status == "success") {
            this.order = res.data.order;

            this.items = res.data.order.items;
            this.files = res.data.order.files;
          } else {
            this.$router.push('/pedidos');
          }

          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/pedidos");
          }
        });
    },
    cancelPedido() {
      if (this.order.id) {
        this.cancel.loading = true;

        api
          .delete("pedidos/" + this.$route.params.id)
          .then((res) => {
            if (res.data.status == "success") {
              this.cancel.modal = false;
              this.$toast.success(res.data.message);

              this.$router.push("/pedidos");
            } else {
              this.$toast.error(res.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.cancel.modal = false;
              this.cancel.loading = false;
            }
          })
          .finally(() => {
            this.cancel.loading = false;
          });
      }
    },
    getSaldo() {
      api.get("financeiro/saldo").then((res) => {
        if (res.data.status == "success") {
          this.balance = res.data.balance;
          this.pay.balance = parseFloat(this.balance) + parseFloat(this.limit) - parseFloat(this.order.total);
        }
      });
    },
    getLimite() {
      api.get("financeiro/limite").then((res) => {
        if (res.data.status == "success") {
          this.limit = res.data.total;
          this.pay.balance = parseFloat(this.balance) + parseFloat(this.limit) - parseFloat(this.order.total);
        }
      });
    },
    closeAll() {
      this.item.add.modal = false;
      this.item.edit.modal = false;
      this.item.del.modal = false;
      this.file.add.modal = false;
      this.file.del.modal = false;
      this.pay.modal = false;
      this.cancel.modal = false;
    },
    showPayPedido() {
      this.closeAll();
      this.getSaldo();
      this.getLimite();
      this.pay.modal = true;
    },
    payPedido() {
      api
        .post("pedidos/pagamento", {
          id: this.$route.params.id
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.pay.modal = false;
            this.$toast.success(res.data.message);

            this.getPedido()
          } else {
            this.$toast.error(res.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            this.pay.modal = false;
            this.pay.loading = false;
          }
        })
        .finally(() => {
          this.pay.loading = false;
        });
    },
    clickItem(item) {
      if (item.id) {
        this.item.edit.modal = false;
        this.item.edit.id = item.id;
        this.item.edit.name = item.name;
        this.item.edit.price = item.price;
        this.item.edit.qty = item.qty;
      }
    },
    showAddItem() {
      this.closeAll();
      this.item.add.modal = true;
    },
    showEditItem(item) {
      this.closeAll();
      this.item.edit.modal = true;
      this.item.edit.id = item.id;
      this.item.edit.name = item.name;
      this.item.edit.price = item.price;
      this.item.edit.qty = item.qty;
    },
    showDelItem(item) {
      this.item.del.modal = false;
      this.item.del.modal = true;
      this.item.del.id = item.id;
    },
    delItem(id) {
      if (id) {
        this.item.del.loading = true;

        api
          .delete("pedidos/" + this.$route.params.id + "/itens/" + id)
          .then((res) => {
            if (res.data.status == "success") {
              this.item.del.modal = false;
              this.$toast.success(res.data.message);

              this.getPedido();
            } else {
              this.$toast.error(res.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.item.del.modal = false;
              this.item.del.loading = false;
            }
          })
          .finally(() => {
            this.item.del.loading = false;
          });
      }
    },
    showAddArquivo() {
      this.closeAll();
      this.file.add.modal = false;
      this.file.add.modal = true;
    },
    addArquivo() {
    },
    showDelArquivo(item) {
      this.closeAll();
      this.file.del.modal = true;
      this.file.del.id = item.id;
    },
    delArquivo(id) {
      if (id) {
        this.file.del.loading = true;

        api
          .delete("pedidos/" + this.$route.params.id + "/arquivos/" + id)
          .then((res) => {
            if (res.data.status == "success") {
              this.file.del.modal = false;
              this.$toast.success(res.data.message);

              this.getPedido();
            } else {
              this.$toast.error(res.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.file.del.modal = false;
              this.file.del.loading = false;
            }
          })
          .finally(() => {
            this.file.del.loading = false;
          });
      }
    },
    clickArquivo(item) {
      if (item.url) {
        window.open(item.url, '_blank');
      }
    },
  },
  mounted() {
    this.getPedido();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/pedidos">Pedidos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Ver pedido</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Ver pedido</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!order" class="card">
      <div class="card-body text-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>
    <template v-else-if="order && order.id">
      <div id="dropdown" class="d-flex mb-4">
        <div class="totals">
          <h6 class="m-0">{{ order.id }}</h6>
          <h6 class="m-0 text-secondary">No. do pedido</h6>
        </div>
        <div class="totals">
          <h6 class="m-0" v-if="order.status == 'open'">Aberto</h6>
          <h6 class="m-0" v-else-if="order.status == 'pending'">Pendente</h6>
          <h6 class="m-0" v-else-if="order.status == 'paid'">Pago</h6>
          <h6 class="m-0" v-else-if="order.status == 'in_processing'">Em separação</h6>
          <h6 class="m-0" v-else-if="order.status == 'shipped'">Enviado</h6>
          <h6 class="m-0" v-else-if="order.status == 'canceled'">Cancelado</h6>
          <h6 class="m-0 text-secondary">Status</h6>
        </div>
        <b-dropdown variant="outline-light">
          <template #button-content>
            Ações
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
            </svg>
          </template>
          <b-dropdown-item v-on:click="showAddItem()" :disabled="order.status !== 'open'">Adicionar produto</b-dropdown-item>
          <b-dropdown-item v-on:click="showAddArquivo()" :disabled="order.status !== 'open'">Adicionar arquivo</b-dropdown-item>
          <b-dropdown-item v-on:click="showPayPedido()" :disabled="order.status !== 'open'">Pagar pedido</b-dropdown-item>
          <b-dropdown-item v-on:click="cancel.modal = true" :disabled="order.status !== 'open'">Cancelar pedido</b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="card">
        <div class="card-header rounded-top">
          <strong>Resumo do pedido</strong>
        </div>
        <div class="card-body">
          <div class="d-flex align-items-center mb-4">
            <div class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
                <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
              </svg>
            </div>
            <div class="ml-3">
              <h6 class="m-0">Cliente</h6>
              <h6 class="font-size-16 font-weight-normal text-secondary m-0">{{ order.client_name }}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center mb-4">
            <div class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z">
                </path>
              </svg>
            </div>
            <div class="ml-3">
              <h6 class="m-0">Itens</h6>
              <h6 class="font-size-16 font-weight-normal text-secondary m-0">{{ order.items.length }}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center mb-4">
            <div class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
                </path>
                <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
              </svg>
            </div>
            <div class="ml-3">
              <h6 class="m-0">Produtos</h6>
              <h6 class="font-size-16 font-weight-normal text-secondary m-0">{{ order.subtotal | currency }}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center mb-4">
            <div class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
                </path>
                <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
              </svg>
            </div>
            <div class="ml-3">
              <h6 class="m-0">Taxa</h6>
              <h6 class="font-size-16 font-weight-normal text-secondary m-0">{{ order.fee | currency }}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
                </path>
                <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
              </svg>
            </div>
            <div class="ml-3">
              <h6 class="m-0">Total</h6>
              <h6 class="font-size-16 font-weight-normal text-secondary m-0">{{ order.total | currency }}</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header rounded-top">
          <strong>Produtos</strong>
        </div>
        <div class="card-body">
          <div v-if="order.items && order.items.length == 0 && order.status == 'canceled'" class="text-center d-flex align-items-center justify-content-center">
            Nenhum produto foi adicionado no pedido.
          </div>
          <div v-else-if="order.items && order.items.length == 0" class="text-center d-flex align-items-center justify-content-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" style="fill: #312F4F;">
                <circle cx="10.5" cy="19.5" r="1.5"></circle>
                <circle cx="17.5" cy="19.5" r="1.5"></circle>
                <path
                  d="M21 7H7.334L6.18 4.23A1.995 1.995 0 0 0 4.333 3H2v2h2.334l4.743 11.385c.155.372.52.615.923.615h8c.417 0 .79-.259.937-.648l3-8A1.003 1.003 0 0 0 21 7zm-4 6h-2v2h-2v-2h-2v-2h2V9h2v2h2v2z">
                </path>
              </svg>
              <h6 class="my-4 text-dark">Adicione produtos em seu pedido.</h6>
              <button class="btn btn-default" v-on:click="showAddItem()">Adicionar produto</button>
            </div>
          </div>
          <div v-else class="table-responsive">
            <b-table :items="order.items" :fields="fields.items" class="table-nowrap table-style table-hover table-pointer min-vh-25" head-variant="light" @row-clicked="clickItem" responsive>
              <template #cell(name)="row">
                <img class="produto-image" :src="row.item.image" />
                {{ row.item.name }}
              </template>
              <template #cell(price)="row">
                {{ row.item.price | currency }}
              </template>
              <template #cell(subtotal)="row">
                {{ row.item.subtotal | currency }}
              </template>
              <template #cell(id)="row">
                <b-dropdown variant="more">
                  <template #button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>
                  <b-dropdown-item class="d-none" v-on:click="showEditItem(row.item)" :disabled="order.status !== 'open'">Editar</b-dropdown-item>
                  <b-dropdown-item v-on:click="showDelItem(row.item)" class="text-danger" :disabled="order.status !== 'open'">Excluir</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header rounded-top">
          <strong>Arquivos</strong>
        </div>
        <div class="card-body">
          <div v-if="order.files && order.files.length == 0 && order.status == 'canceled'" class="text-center d-flex align-items-center justify-content-center">
            Nenhum arquivo foi adicionado no pedido.
          </div>
          <div v-else-if="order.files && order.files.length == 0" class="text-center d-flex align-items-center justify-content-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" style="fill: #312F4F;">
                <path d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18 5 5h-5V4zM8 14h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z"></path>
              </svg>
              <h6 class="my-4 text-dark">Adicione os arquivos da etiqueta de envio e a declaração de conteúdo ou nota fiscal.</h6>
              <button class="btn btn-default" v-on:click="showAddArquivo()">Adicionar arquivo</button>
            </div>
          </div>
          <div v-else class="table-responsive">
            <b-table :items="order.files" :fields="fields.files" class="table-nowrap table-style table-hover table-pointer min-vh-25" head-variant="light" @row-clicked="clickArquivo" responsive>
              <template #cell(name)="row">
                {{ row.item.url.split('/').pop() }}
              </template>
              <template #cell(url)="row">
                <a target="_blank" :href="row.item.url">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                    <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
                    <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
                  </svg>
                </a>
              </template>
              <template #cell(id)="row">
                <b-dropdown variant="more">
                  <template #button-content>
                    <i class="fas fa-ellipsis-h"></i>
                  </template>
                  <b-dropdown-item v-on:click="showDelArquivo(row.item)" class="text-danger" :disabled="order.status !== 'open'">Excluir</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </template>

    <template v-if="order && order.id">
      <ItemAdd :order="order.id" :modal="item.add.modal" />
      <ItemEdit :order="order.id" :modal="item.edit.modal" />
    </template>

    <b-modal id="item-del" v-if="order && order.id" v-model="item.del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir produto</h4>
        <p>Deseja realmente excluir esse produto?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delItem(item.del.id)">
            Confirmar
            <b-spinner v-if="item.del.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="item.del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <b-modal id="file-add" v-if="order && order.id" v-model="file.add.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-5">Adicionar arquivo</h4>
        <div>
          <AquivosUpload :order="order.id"></AquivosUpload>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <a class="btn btn-outline-link" v-on:click="file.add.modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </div>
    </b-modal>

    <b-modal id="file-del" v-if="order && order.id" v-model="file.del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir arquivo</h4>
        <p>Deseja realmente excluir esse arquivo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delArquivo(file.del.id)">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="file.del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <b-modal id="pay" v-if="order && order.id" v-model="pay.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Pagar pedido</h4>
        <template v-if="order.items && order.items.length == 0">
          <p>Adicione produtos em seu pedido.</p>
          <div class="d-flex">
            <button class="btn btn-outline-link" v-on:click="pay.modal = false">Fechar <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="order.files && order.files.length == 0">
          <p>Adicione os arquivos da etiqueta de envio e a declaração de conteúdo ou nota fiscal.</p>
          <div class="d-flex">
            <button class="btn btn-outline-link" v-on:click="pay.modal = false">Fechar <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else>
          <template v-if="pay.balance > 0">
            <p>Deseja realmente pagar esse pedido?</p>
            <p>Seu saldo atual é de <strong>{{ balance | currency }}</strong> e seu saldo após o pagamento será de <strong>{{ balance - order.total | currency }}</strong>.</p>
            <div class="d-flex">
              <button class="btn btn-default mr-3" v-on:click="payPedido()">Confirmar</button>
              <button class="btn btn-outline-link" v-on:click="pay.modal = false">Fechar <sup>ESC</sup></button>
            </div>
          </template>
          <template v-else>
            <p>No momento você não possui saldo suficiente para realizar o pagamento do pedido.</p>
            <div class="d-flex">
              <button class="btn btn-outline-link" v-on:click="pay.modal = false">Fechar <sup>ESC</sup></button>
            </div>
          </template>
        </template>
      </div>
    </b-modal>

    <b-modal id="cancel" v-if="order && order.id" v-model="cancel.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Cancelar pedido</h4>
        <p>Deseja realmente cancelar esse pedido?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="cancelPedido">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="cancel.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.produto-image {
  border-radius: 5px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
</style>